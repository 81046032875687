var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import Loader from '../../Loader/index';
import styles from './styles.module.scss';
export var ButtonVariants;
(function (ButtonVariants) {
    ButtonVariants["PRIMARY"] = "primary";
    ButtonVariants["PRIMARY_DARK"] = "primaryDark";
    ButtonVariants["SECONDARY"] = "secondary";
})(ButtonVariants || (ButtonVariants = {}));
export var SecondaryButtonType;
(function (SecondaryButtonType) {
    SecondaryButtonType["GRAY"] = "gray";
    SecondaryButtonType["GREEN"] = "green";
    SecondaryButtonType["GOLD"] = "gold";
    SecondaryButtonType["MARINE"] = "marine";
})(SecondaryButtonType || (SecondaryButtonType = {}));
const Button = (_a) => {
    var { className = '', buttonClassName = '', contentClassName = '', disabled, isLoading, children, variant = ButtonVariants.PRIMARY, secondaryType, 
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleButtonClick = () => { } } = _a, props = __rest(_a, ["className", "buttonClassName", "contentClassName", "disabled", "isLoading", "children", "variant", "secondaryType", "handleButtonClick"]);
    return (_jsx("button", Object.assign({ className: buttonClassName, disabled: disabled, onClick: handleButtonClick }, props, { children: _jsx("div", { className: classNames(styles['btn-wrapper'], className, {
                [styles['disabled']]: disabled,
            }), children: _jsx("div", { className: classNames(styles['btn'], {
                    [styles['disabled']]: disabled,
                    [styles['btn_primary']]: variant === ButtonVariants.PRIMARY,
                    [styles['btn_primaryDark']]: variant === ButtonVariants.PRIMARY_DARK,
                    [styles['btn_secondary']]: variant === ButtonVariants.SECONDARY,
                    [styles['btn_secondary-gray']]: secondaryType === SecondaryButtonType.GRAY,
                    [styles['btn_secondary-green']]: secondaryType === SecondaryButtonType.GREEN,
                    [styles['btn_secondary-gold']]: secondaryType === SecondaryButtonType.GOLD,
                    [styles['btn_secondary-marine']]: secondaryType === SecondaryButtonType.MARINE,
                }), children: _jsxs("div", { className: classNames(styles['btn'], {
                        [styles['btn_primary-middle']]: variant === ButtonVariants.PRIMARY,
                        [styles['btn_primaryDark-middle']]: variant === ButtonVariants.PRIMARY_DARK,
                    }), children: [isLoading && _jsx(Loader, { className: styles['loader'] }), _jsx("div", { className: classNames(styles['content'], {
                                [styles['loading']]: isLoading,
                                [styles['btn_primary-wrap']]: variant === ButtonVariants.PRIMARY,
                                [styles['btn_primaryDark-wrap']]: variant === ButtonVariants.PRIMARY_DARK,
                            }, contentClassName), children: children })] }) }) }) })));
};
export default Button;
