import axios from 'axios';
import { logout } from './features/userSlice';
import { store } from './store';
// Add a response interceptor
axios.interceptors.response.use(function (res) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return res;
}, function (error) {
    // Unauthorized - in this case it means the cookie token has expired
    // Force a google logout
    // Not currently working,
    if (error.response.status === 401) {
        store.dispatch(logout());
        return;
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});
// Add a request interceptor
// axios.interceptors.request.use(
//   function (config) {
//     // Do something before request is sent
//     return config;
//   },
//   function (error) {
//     // Do something with request error
//     return Promise.reject(error);
//   },
// );
// If you need to remove an interceptor later you can.
// const myInterceptor = axios.interceptors.request.use(function () {
//   /*...*/
// });
// axios.interceptors.request.eject(myInterceptor);
