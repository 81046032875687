import { jsx as _jsx } from "react/jsx-runtime";
import './redux/axios-interceptors';
import './styles/base.scss';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './redux/store';
const GOOGLE_OAUTH_CLIENT_ID = '159753250763-1e92cfqppqca5d0if9p28u8krusddvbi.apps.googleusercontent.com';
const root = createRoot(document.getElementById('root'));
root.render(_jsx(GoogleOAuthProvider, { clientId: GOOGLE_OAUTH_CLIENT_ID, children: _jsx(Provider, { store: store, children: _jsx(App, {}) }) }));
