import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
const MenuButton = ({ className = '', disabled, iconImg, linkName, navigateTo, isExpanded, isCreateButton = false, }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isActive = location.pathname === navigateTo;
    const handleClick = () => {
        if (!disabled) {
            navigate(navigateTo);
        }
    };
    return (_jsx("button", { className: classNames(styles.buttonWrapper, className, {
            [styles.disabled]: disabled,
            [styles.active]: isActive,
            [styles.collapsed]: !isExpanded,
            [styles.createButton]: isCreateButton,
        }), onClick: handleClick, disabled: disabled, children: _jsxs("div", { className: styles.buttonWrapper_content, children: [_jsx("img", { className: classNames(styles.buttonWrapper_content_img, {
                        [styles.pulseEffect]: isCreateButton,
                    }), src: iconImg, alt: linkName }), isExpanded && _jsx("div", { children: linkName })] }) }));
};
export default MenuButton;
