import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router';
import NoMobilePage from 'src/components/NoMobile';
import CommonSystemModals from 'src/layout/CommonSystemModals';
import { Footer } from 'src/layout/footer';
import { Header } from 'src/layout/header';
import { SideBar } from 'src/layout/sideBar';
import style from './styles.module.scss';
const MainLayout = () => {
    const user = useSelector((state) => state.userSlice.profile);
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const checkIsMobile = () => {
            setIsMobile(window.innerWidth <= 820);
        };
        checkIsMobile();
        window.addEventListener('resize', checkIsMobile);
        return () => {
            window.removeEventListener('resize', checkIsMobile);
        };
    }, []);
    return (_jsxs("div", { className: style.homePageWrapper, children: [user && (_jsx("div", { className: style.homePageWrapper_sidebar, children: _jsx(SideBar, {}) })), _jsxs("div", { className: style.contentContainer, children: [_jsx(Header, {}), _jsx("main", { className: style.mainContent, children: isMobile ? (_jsx(NoMobilePage, {})) : (_jsx("div", { className: style.contentContainer_content, children: _jsx(Suspense, { fallback: _jsx("div", { children: "LOADER" }), children: _jsx(Outlet, {}) }) })) }), _jsx(Footer, {}), _jsx(CommonSystemModals, {})] })] }));
};
export default MainLayout;
