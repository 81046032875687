import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useDispatch, useSelector } from 'react-redux';
import DownloadModal from 'src/components/DownloadModal';
import { selectDownloadAppModalState, setShowSystemModal, SYSTEM_MODALS, } from 'src/redux/features/applicationSlice';
const CommonSystemModals = () => {
    const dispatch = useDispatch();
    const downloadModalState = useSelector(selectDownloadAppModalState);
    const onCloseDownloadModal = () => {
        dispatch(setShowSystemModal({
            modalName: SYSTEM_MODALS.downloadLauncher,
            show: false,
        }));
    };
    return (_jsx(_Fragment, { children: downloadModalState.show && (_jsx(DownloadModal, { open: downloadModalState.show, handleClose: onCloseDownloadModal, isInstalled: false, isMacOs: Boolean(navigator.userAgent.includes('Mac')) })) }));
};
export default CommonSystemModals;
