var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import alteraApi from 'src/api/rtk/alteraApi';
import { HTTP_METHODS } from 'src/api/rtk/constants';
import { handleErrorQuery } from 'src/api/rtk/helpers';
import { AGENT_TAGS } from 'src/api/rtk/services/agent';
import { getAccessAuthTokenHeaders, transformPayloadToQuery, } from 'src/api/rtk/utils';
import { MIXPANEL_EVENTS, MIXPANEL_PROPERTIES } from 'src/constants/mixpanel';
import { URLS } from 'src/constants/urls';
import { mixpanelClient } from 'src/services/mixpanel';
import { logout, refreshAuthSession, setInvitationError, setUserApiKey, setUserProfile, startAuthSession, } from 'src/redux/features/userSlice';
import { USER_TAGS } from './constants';
const userRtk = alteraApi.injectEndpoints({
    endpoints: (build) => ({
        getCurrentUser: build.query({
            query() {
                return { url: URLS.user.getCurrentUser };
            },
            onQueryStarted: (_1, _a) => __awaiter(void 0, [_1, _a], void 0, function* (_, { dispatch, queryFulfilled }) {
                try {
                    const { data: profileData } = yield queryFulfilled;
                    if (profileData) {
                        dispatch(setUserProfile({ profile: profileData }));
                    }
                }
                catch (errResp) {
                    handleErrorQuery(errResp);
                }
            }),
            providesTags: [USER_TAGS.profileInfo],
        }),
        getCurrentUserApiKey: build.query({
            query() {
                return { url: URLS.keys.getApiKeys };
            },
            onQueryStarted: (_2, _b) => __awaiter(void 0, [_2, _b], void 0, function* (_, { dispatch, queryFulfilled }) {
                try {
                    const { data: apiKeysData } = yield queryFulfilled;
                    const apiKey = apiKeysData.data.length
                        ? apiKeysData.data[0].key
                        : null;
                    dispatch(setUserApiKey(apiKey));
                }
                catch (errResp) {
                    dispatch(setUserApiKey(null));
                    handleErrorQuery(errResp);
                }
            }),
            providesTags: [USER_TAGS.apiInfo],
        }),
        createUserApiKey: build.mutation({
            query: () => {
                const payload = { name: 'minecraft' };
                return {
                    url: URLS.keys.createApiKey,
                    method: HTTP_METHODS.POST,
                    body: payload,
                };
            },
            invalidatesTags: [USER_TAGS.apiInfo],
        }),
        getProfileViaDiscord: build.query({
            query() {
                return {
                    url: URLS.user.loginDiscord,
                    headers: getAccessAuthTokenHeaders(),
                };
            },
            onQueryStarted: (_3, _c) => __awaiter(void 0, [_3, _c], void 0, function* (_, { dispatch, queryFulfilled }) {
                var _d;
                try {
                    const { data, meta } = yield queryFulfilled;
                    // Update profile in store
                    dispatch(setUserProfile({
                        profile: data,
                        socialMedia: 'discord',
                    }));
                    dispatch(startAuthSession({}));
                    // invalidate userApi cache to fetch actual user's api key
                    dispatch(alteraApi.util.invalidateTags([
                        USER_TAGS.apiInfo,
                        USER_TAGS.profileInfo,
                        AGENT_TAGS.agentList,
                    ]));
                    // track event in mixpanel
                    if (((_d = meta === null || meta === void 0 ? void 0 : meta.response) === null || _d === void 0 ? void 0 : _d.status) === 201) {
                        mixpanelClient.track(MIXPANEL_EVENTS.SignUp, {
                            [MIXPANEL_PROPERTIES.AuthType]: 'Discord',
                        });
                    }
                    else {
                        mixpanelClient.track(MIXPANEL_EVENTS.SignIn);
                    }
                }
                catch (errResp) {
                    handleErrorQuery(errResp);
                }
            }),
        }),
        getProfileViaGoogle: build.query({
            query() {
                return {
                    url: URLS.user.loginGoogle,
                    headers: getAccessAuthTokenHeaders(),
                };
            },
            onQueryStarted: (_4, _e) => __awaiter(void 0, [_4, _e], void 0, function* (_, { dispatch, queryFulfilled }) {
                var _f;
                try {
                    const { data, meta } = yield queryFulfilled;
                    // Update profile in store
                    dispatch(setUserProfile({
                        profile: data,
                        socialMedia: 'google',
                    }));
                    dispatch(startAuthSession({}));
                    // invalidate userApi cache to fetch actual user's api key
                    dispatch(alteraApi.util.invalidateTags([
                        USER_TAGS.apiInfo,
                        USER_TAGS.profileInfo,
                        AGENT_TAGS.agentList,
                    ]));
                    // track event in mixpanel
                    if (((_f = meta === null || meta === void 0 ? void 0 : meta.response) === null || _f === void 0 ? void 0 : _f.status) === 201) {
                        mixpanelClient.track(MIXPANEL_EVENTS.SignUp, {
                            [MIXPANEL_PROPERTIES.AuthType]: 'Google',
                        });
                    }
                    else {
                        mixpanelClient.track(MIXPANEL_EVENTS.SignIn);
                    }
                }
                catch (errResp) {
                    handleErrorQuery(errResp);
                }
            }),
        }),
        refreshSession: build.mutation({
            query: () => ({
                url: URLS.user.refreshSession,
                method: HTTP_METHODS.POST,
            }),
            onQueryStarted: (_5, _g) => __awaiter(void 0, [_5, _g], void 0, function* (_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = yield queryFulfilled;
                    dispatch(refreshAuthSession(data));
                }
                catch (errResp) {
                    handleErrorQuery(errResp);
                    dispatch(logout());
                }
            }),
        }),
        userApplyInvitationCode: build.query({
            query({ invitationCode }) {
                const query = transformPayloadToQuery({
                    payload: {
                        invitation_id: invitationCode,
                    },
                });
                return { url: `${URLS.invitation.accept}${query}` };
            },
            onQueryStarted: (arg_1, _h) => __awaiter(void 0, [arg_1, _h], void 0, function* (arg, { dispatch, queryFulfilled }) {
                var _j, _k;
                try {
                    yield queryFulfilled;
                    // INVALIDATE USER INFO CACHE
                    dispatch(alteraApi.util.invalidateTags([USER_TAGS.profileInfo]));
                }
                catch (errResp) {
                    const errorMessage = ((_k = (_j = errResp === null || errResp === void 0 ? void 0 : errResp.error) === null || _j === void 0 ? void 0 : _j.data) === null || _k === void 0 ? void 0 : _k.message) ||
                        'Error while applying invitation code';
                    dispatch(setInvitationError(errorMessage));
                }
            }),
        }),
        // LOGOUT USER
        userLogout: build.query({
            query() {
                return { url: URLS.user.logout };
            },
            onQueryStarted: (_6, _l) => __awaiter(void 0, [_6, _l], void 0, function* (_, { dispatch, queryFulfilled }) {
                try {
                    yield queryFulfilled;
                    dispatch(logout());
                }
                catch (errResp) {
                    handleErrorQuery(errResp);
                }
            }),
        }),
    }),
    overrideExisting: true,
});
export const { useGetCurrentUserQuery, useGetCurrentUserApiKeyQuery, useLazyGetProfileViaDiscordQuery, useLazyGetProfileViaGoogleQuery, useLazyUserLogoutQuery, useRefreshSessionMutation, useCreateUserApiKeyMutation, useLazyUserApplyInvitationCodeQuery, } = userRtk;
