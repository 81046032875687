export var MIXPANEL_EVENTS;
(function (MIXPANEL_EVENTS) {
    MIXPANEL_EVENTS["ClickFeaturedAgentCardNonAuth"] = "Featured Agent Clicked - Not Authenticated";
    MIXPANEL_EVENTS["ClickAgentCardNonAuth"] = "Agent Clicked - Not Authenticated";
    MIXPANEL_EVENTS["ChatWithAgentInstance"] = "Chat with Agent Instance";
    MIXPANEL_EVENTS["SummonAgent"] = "Summon Agent";
    MIXPANEL_EVENTS["SummonAgentFreshStart"] = "Summon Agent - Start Fresh";
    MIXPANEL_EVENTS["SummonAgentInstance"] = "Summon Agent - Dive Back In";
    MIXPANEL_EVENTS["SignUp"] = "Sign Up";
    MIXPANEL_EVENTS["SignIn"] = "Login";
    MIXPANEL_EVENTS["AgentEntityCreate"] = "Create Agent";
    MIXPANEL_EVENTS["AgentEntityCreateFailed"] = "Failed Create Agent";
    MIXPANEL_EVENTS["AgentEntityCreateStart"] = "Start Create Agent";
    MIXPANEL_EVENTS["AgentEntityCreateStartFromSidebar"] = "Start Create Agent [Sidebar]";
    MIXPANEL_EVENTS["AgentEntityCreateStartNoOwnedAgents"] = "Start Create Agent [No Owned Agents Found]";
    MIXPANEL_EVENTS["AgentEntityUpdate"] = "Update Agent";
})(MIXPANEL_EVENTS || (MIXPANEL_EVENTS = {}));
export var MIXPANEL_PROPERTIES;
(function (MIXPANEL_PROPERTIES) {
    MIXPANEL_PROPERTIES["UserId"] = "User ID";
    MIXPANEL_PROPERTIES["AgentName"] = "Agent Name";
    MIXPANEL_PROPERTIES["AuthType"] = "authentication type";
    MIXPANEL_PROPERTIES["AgentType"] = "Type";
})(MIXPANEL_PROPERTIES || (MIXPANEL_PROPERTIES = {}));
