var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useGoogleLogin } from '@react-oauth/google';
import { useEffect, useState } from 'react';
import { useDiscordLogin } from 'react-discord-login';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLazyGetProfileViaDiscordQuery, useLazyGetProfileViaGoogleQuery, useLazyUserApplyInvitationCodeQuery, } from 'src/api/rtk/services/user';
import Button, { ButtonVariants } from 'src/components/Buttons/Button';
import Modal from 'src/components/Modals';
import InviteCode from 'src/components/Modals/ModalsContent/InviteCode';
import JoinProModal from 'src/components/Modals/ModalsContent/JoinProModal';
import LoginModal from 'src/components/Modals/ModalsContent/LoginModal';
import { formatNumber } from 'src/helpers/formatting';
import { useAuthModal } from 'src/context/AuthModalContext';
import useSearchHandlers from 'src/layout/header/hooks/useSearchHandlers';
import { setGlobalFilters } from 'src/redux/features/agentSlice';
import { setSocialAuth } from 'src/redux/features/userSlice';
import { PAGES } from 'src/router/pages';
import style from './styles.module.scss';
const appVersion = process.env.APP_VERSION || '/ 0.1';
export const Header = ({ hasSearchField = true }) => {
    var _a;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const user = useSelector((state) => state.userSlice.profile);
    const [isInviteCodeOpen, setIsInviteCodeOpen] = useState(false);
    const [countdown, setCountdown] = useState('');
    const [isJoinProModalOpen, setIsJoinProModalOpen] = useState(false);
    const [getProfileDiscord] = useLazyGetProfileViaDiscordQuery();
    const [getProfileGoogle] = useLazyGetProfileViaGoogleQuery();
    const [applyInvitationCode] = useLazyUserApplyInvitationCodeQuery();
    const { isModalOpen, closeModal, openModal } = useAuthModal();
    const outOfMinutes = ((_a = user === null || user === void 0 ? void 0 : user.minutes) !== null && _a !== void 0 ? _a : 0) <= 0;
    const searchHandlers = useSearchHandlers({ dispatch, searchValue: search });
    const handleMinutesClick = () => {
        setIsJoinProModalOpen(true);
    };
    const onCloseJoinProModal = () => {
        setIsJoinProModalOpen(false);
    };
    const handleUpgradeSub = () => {
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = '/api/v1/stripe/checkout';
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = 'product_name';
        hiddenField.value = 'premium';
        form.appendChild(hiddenField);
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
    };
    useEffect(() => {
        if (user && outOfMinutes) {
            setIsJoinProModalOpen(true);
        }
        else
            setIsJoinProModalOpen(false);
    }, [user, outOfMinutes]);
    const onLogInClick = () => {
        openModal();
    };
    const googleLogin = useGoogleLogin({
        onSuccess: (response) => __awaiter(void 0, void 0, void 0, function* () {
            yield dispatch(setSocialAuth(response));
            yield getProfileGoogle({}).unwrap();
            closeModal();
        }),
        flow: 'implicit',
    });
    const discordLoginParams = {
        clientId: '1260729475088519230',
        redirectUri: `${window.location.origin}/`,
        responseType: 'token',
        scopes: ['identify', 'email', 'connections', 'openid'],
        onSuccess: (response) => __awaiter(void 0, void 0, void 0, function* () {
            localStorage.setItem('discordResponse', JSON.stringify(response));
            yield dispatch(setSocialAuth(response));
            yield getProfileDiscord({}).unwrap();
        }),
        onFailure: (error) => {
            console.error('Discord login failed:', error);
        },
    };
    const { buildUrl } = useDiscordLogin(discordLoginParams);
    const handleInviteCode = (formData) => __awaiter(void 0, void 0, void 0, function* () {
        yield applyInvitationCode({ invitationCode: formData.inviteCode }).unwrap();
    });
    const openInviteCodeModal = () => {
        setIsJoinProModalOpen(false);
        setIsInviteCodeOpen(true);
    };
    useEffect(() => {
        const updateCountdown = () => {
            const now = new Date();
            const midnight = new Date(now);
            midnight.setUTCHours(24, 0, 0, 0);
            const diff = midnight.getTime() - now.getTime();
            const hours = Math.floor(diff / (1000 * 60 * 60));
            const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
            setCountdown(`${hours.toString().padStart(2, '0')} hrs ${minutes.toString().padStart(2, '0')} mins`);
        };
        updateCountdown();
        const intervalId = setInterval(updateCountdown, 1000);
        return () => clearInterval(intervalId);
    }, []);
    return (_jsxs("div", { className: style.headerWrap, children: [_jsx("div", { onClick: () => {
                    dispatch(setGlobalFilters({ search: '' }));
                    navigate(PAGES.Discover);
                }, className: style.headerWrap_logo }), hasSearchField && (_jsx("div", { className: style.headerWrap_middleBlock, children: _jsxs("div", { className: style.searchInputWrapper, children: [_jsx("div", { className: style.searchInputWrapper_prtfix, children: _jsx("img", { src: '/assets/images/search.svg', alt: 'search' }) }), _jsx("input", { className: style.searchInputWrapper_input, type: 'text', value: search, placeholder: 'Search', onChange: (event) => setSearch(event.target.value), onBlur: searchHandlers.onBlur, onKeyDown: searchHandlers.onKeyDown })] }) })), _jsx("div", { className: style.headerWrap_rightBlock, children: user ? (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                _jsxs("div", { onClick: handleMinutesClick, className: style.headerWrap_rightBlock_userMinutes, children: [_jsxs("p", { className: style.headerWrap_rightBlock_userMinutes_countdown, children: [_jsx("span", { children: countdown }), " until refresh"] }), _jsxs("span", { className: style.headerWrap_rightBlock_userMinutes_text, children: [formatNumber(user.minutes), " play minutes"] })] })) : (_jsx("div", { children: _jsx(Button, { handleButtonClick: onLogInClick, variant: ButtonVariants.PRIMARY, children: "Log In" }) })) }), _jsx(Modal, { open: isJoinProModalOpen, onClose: onCloseJoinProModal, children: _jsx(JoinProModal, { onJoin: handleUpgradeSub, showInviteCodeButton: true, onInviteCodeClick: openInviteCodeModal, context: 'header' }) }), _jsx(Modal, { open: isInviteCodeOpen, onClose: () => setIsInviteCodeOpen(false), children: _jsx(InviteCode, { actionClick: handleInviteCode, onClose: () => setIsInviteCodeOpen(false), currentTIme: (user === null || user === void 0 ? void 0 : user.minutes) || 0 }) }), isModalOpen && !user && (_jsx(LoginModal, { googleLogin: googleLogin, isOpen: isModalOpen, onClose: closeModal, buildUrl: buildUrl }))] }));
};
