import { configureStore } from '@reduxjs/toolkit';
import { throttle } from 'lodash';
import { alteraApi } from 'src/api/rtk';
import { loadState, saveState } from '../helpers/localstorage';
import { authMiddleware } from './middlewares';
import reducer from './reducers';
export const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(alteraApi.middleware).concat(authMiddleware),
    devTools: true,
    preloadedState: loadState(),
});
store.subscribe(throttle(() => {
    saveState({ userSlice: store.getState().userSlice });
}, 1000));
