import mixpanel from 'mixpanel-browser';
class MixpanelClient {
    constructor() {
        this.isActive = false;
    }
    init() {
        var _a;
        // if mixpanel is already active, skip init process
        if (this.isActive)
            return this.isActive;
        // extract token from env
        const mixpanelToken = (_a = process.env.MIXPANEL_PROJECT_TOKEN) !== null && _a !== void 0 ? _a : '';
        // init mixpanel if token was found
        if (mixpanelToken) {
            mixpanel.init(mixpanelToken, {
                track_pageview: true,
                persistence: 'localStorage',
            });
            // indicate that mixpanel can be used for tracking
            this.isActive = true;
        }
        return this.isActive;
    }
    identify(userInfo) {
        // skip if mixpanel is not active
        if (!this.isActive)
            return;
        // identify user
        mixpanel.identify(userInfo.userId);
        mixpanel.people.set({
            // Do not track actual user names in production
            $name: userInfo.userId,
            // Do not track actual user emails in production
            $email: userInfo.userId,
            'user type': userInfo.userAccessTier,
            'last login time': userInfo.userLastLogin,
        });
    }
    reset() {
        // skip if mixpanel is not active
        if (!this.isActive)
            return;
        // reset identification
        mixpanel.reset();
    }
    track(eventName, properties) {
        // skip if mixpanel is not active
        if (!this.isActive)
            return;
        // track event
        mixpanel.track(eventName, properties);
    }
}
export const mixpanelClient = new MixpanelClient();
mixpanelClient.init();
export default MixpanelClient;
