export const URLS = {
    user: {
        getCurrentUser: '/api/v1/user/current',
        loginGoogle: '/api/v1/user/login/social/google',
        loginDiscord: '/api/v1/user/login/social/discord',
        logout: '/api/v1/user/logout',
        refreshSession: '/api/v1/user/refresh_session',
        sendFeedback: '/api/v1/user/feedback/send',
    },
    invitation: {
        accept: '/api/v1/invitation/accept',
    },
    agent: {
        getAgentsByGroup: '/api/v1/agent/list',
        getOwnedAgents: '/api/v1/agent/owned',
        getAgentsByGroupPublic: '/api/v1/public/agent/list',
        getAgentSkins: '/api/v1/public/agent/skin',
        getInstances: '/api/v1/agent/latest_instances',
        get: '/api/v1/agent/get',
        groups: '/api/v1/agent/groups',
        groupsPublic: '/api/v1/public/agent/groups',
        getInstanceStatusByAgentId: '/api/v1/agent/instance_status',
        getInstanceById: '/api/v1/agent/instance/get',
        getInstanceHistory: '/api/v1/agent/instance/history',
        search: '/api/v1/agent/search',
        searchPublic: '/api/v1/public/agent/search',
        archive: '/api/v1/agent/archive',
        copy: '/api/v1/agent/copy',
        directChatInstanceSaveMessage: '/api/v1/agent/instance/save_chat',
        directChatInstanceGetChat: '/api/v1/agent/instance/get_chat',
        archiveInstance: '/api/v1/agent/instance/archive',
        checkInstanceExists: '/api/v1/agent/instance/check_exists',
        fetchAgentInstances: '/api/v1/agent/fetch_agent_instances',
    },
    agent_creation: {
        chat: '/api/v1/agent_creation/chat',
        finishChat: '/api/v1/agent_creation/finish_chat',
        getChat: '/api/v1/agent_creation/get_chat',
    },
    keys: {
        getApiKeys: '/api/v1/keys/list',
        deleteApiKey: '/api/v1/keys/delete',
        createApiKey: '/api/v1/keys/create',
    },
    messages: {
        list: '/api/v1/messages',
    },
    games: {
        summonAgent: '/api/v1/game/summon_agent',
        stopGameSession: '/api/v1/game/stop_session',
    },
};
export const WAITLIST_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSfl1ODEIHbKgVvbJo0NOWxE3NcfFm-7zKHpbVdMpRDr_05Mzg/viewform';
