import { isAction } from '@reduxjs/toolkit';
import { logout } from 'src/redux/features/userSlice';
const authMiddleware = (store) => (next) => (action) => {
    var _a, _b, _c;
    if (isAction(action)) {
        if (action.type.startsWith('alteraApi/')) {
            if (
            // @ts-ignore
            ((_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.status) === 401) {
                const state = store.getState();
                const wasUserAuth = ((_b = state.userSlice) === null || _b === void 0 ? void 0 : _b.socialAuth) || ((_c = state.userSlice) === null || _c === void 0 ? void 0 : _c.auth);
                if (wasUserAuth) {
                    store.dispatch(logout());
                }
            }
        }
    }
    return next(action);
};
export default authMiddleware;
