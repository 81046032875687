import { createSelector } from 'reselect';
const agentState = (state) => state.agentSlice;
// Generic selector for all agent list types
const selectAgentListByType = (listType) => createSelector(agentState, (state) => { var _a; return ((_a = state === null || state === void 0 ? void 0 : state.agentLists[listType]) === null || _a === void 0 ? void 0 : _a.list) || []; });
// Generic selector for all agent instance list types
const selectAgentInstanceListByType = (listType) => createSelector(agentState, (state) => { var _a; return ((_a = state === null || state === void 0 ? void 0 : state.instances[listType]) === null || _a === void 0 ? void 0 : _a.list) || []; });
export const selectGlobalFilterSearch = createSelector(agentState, (state) => state.globalFilters.search);
export const selectAgentListByTypeFunction = (state, listType) => selectAgentListByType(listType)(state);
export const selectAgentInstanceListByTypeFunction = (state, listType) => selectAgentInstanceListByType(listType)(state);
export const selectSelectedInstance = createSelector(agentState, (state) => state.selectedInstance);
