// formatting.tsx
/**
 * Formats a number into a human-readable string with suffixes like 'k', 'M', 'B'.
 * @param num - The number to format.
 * @returns A formatted string representation of the number.
 */
export const formatNumber = (num) => {
    if (num >= 1e9) {
        return (num / 1e9).toFixed(1) + 'B';
    }
    else if (num >= 1e6) {
        return (num / 1e6).toFixed(1) + 'M';
    }
    else if (num >= 1e3) {
        return (num / 1e3).toFixed(1) + 'k';
    }
    else {
        return num.toString();
    }
};
// Example usage:
// import { formatNumber } from './formatting';
// const formattedMinutes = formatNumber(1500); // "1.5k"
