var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
/**
 * Basic Image Component
 *
 * Renders an image with error handling. If the `src` image fails to load,
 * the component will switch to the `defaultSrc` if provided.
 * Supports all standard <img> attributes.
 *
 * Props:
 * - `defaultSrc` (string, optional): Fallback image URL on load failure.
 * - Other standard <img> tag attributes.
 */
const Image = (_a) => {
    var { src, defaultSrc } = _a, props = __rest(_a, ["src", "defaultSrc"]);
    const [currentSrc, setCurrentSrc] = useState(src || defaultSrc || '');
    const onSrcError = () => {
        if (currentSrc === src && defaultSrc) {
            setCurrentSrc(defaultSrc);
        }
    };
    useEffect(() => {
        setCurrentSrc(src || defaultSrc || '');
    }, [src, defaultSrc, setCurrentSrc]);
    if (!currentSrc)
        return null;
    return (_jsx("img", Object.assign({ src: currentSrc, alt: props.alt, onError: onSrcError }, props)));
};
export default Image;
