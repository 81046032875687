import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import ReactGA4 from 'react-ga4';
import { RouterProvider } from 'react-router-dom';
import useMixpanelService from 'src/hooks/useMixpanelService';
import { AuthModalProvider } from 'src/context/AuthModalContext';
import { ThemeProvider } from 'src/context/ThemeContext';
import { router } from 'src/router';
import { useGetCurrentUser, useGetCurrentUserApiKey } from './hooks/user';
function App() {
    useEffect(() => {
        var _a;
        ReactGA4.initialize((_a = process.env.REACT_APP_GOOGLE_ANALITYCS_KEY) !== null && _a !== void 0 ? _a : 'G-F1DH5D2S6B');
    }, []);
    useGetCurrentUser();
    useGetCurrentUserApiKey();
    useMixpanelService();
    return (_jsx(AuthModalProvider, { children: _jsx(ThemeProvider, { children: _jsx(RouterProvider, { router: router }) }) }));
}
export default App;
