import { store } from 'src/redux/store';
export const getAccessAuthTokenHeaders = () => {
    var _a;
    const headers = {};
    const state = store.getState();
    const userAuthToken = (_a = state.userSlice.socialAuth) === null || _a === void 0 ? void 0 : _a.access_token;
    if (userAuthToken) {
        headers.Authorization = userAuthToken;
    }
    return headers;
};
export const transformPayloadToQuery = ({ payload, urlEncode = true, excludeEmpty = false, addPrefix = true, }) => {
    const _payload = typeof payload === 'object' ? payload : {};
    const isPayloadQuery = Boolean(Object.keys(_payload).length);
    if (!isPayloadQuery)
        return '';
    const resultQuery = Object.entries(_payload)
        .map(([key, value]) => {
        if (excludeEmpty && !value)
            return null;
        let _value;
        if (value === null || value === undefined) {
            _value = '';
        }
        else if (typeof value === 'number') {
            _value = String(value);
        }
        else {
            _value = value;
        }
        _value = urlEncode ? encodeURIComponent(_value) : _value;
        return `${key}=${_value}`;
    })
        .filter(Boolean)
        .join('&');
    const prefix = addPrefix ? '?' : '';
    return `${prefix}${resultQuery}`;
};
