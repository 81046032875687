import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { LoaderIcon } from '../Icons';
import styles from './styles.module.scss';
const Loader = ({ fullPage, absolute, backdrop, className }) => {
    return (_jsx("div", { className: classNames(className, styles['loader'], {
            [styles['page-loader-wrapper']]: fullPage,
            [styles['loader-wrapper']]: !fullPage,
            [styles['absolute']]: absolute && !fullPage,
            [styles['backdrop']]: backdrop,
        }), children: _jsx(LoaderIcon, { className: classNames('icon', styles['icon'], {
                [styles['full-page']]: fullPage,
            }) }) }));
};
export default Loader;
