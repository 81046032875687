export var AGENT_LIST_TYPES;
(function (AGENT_LIST_TYPES) {
    AGENT_LIST_TYPES["Featured"] = "featured";
    AGENT_LIST_TYPES["CommunityFavourite"] = "community_favorite";
    AGENT_LIST_TYPES["Trending"] = "trending";
    AGENT_LIST_TYPES["Owned"] = "owned";
    AGENT_LIST_TYPES["Searched"] = "searched";
})(AGENT_LIST_TYPES || (AGENT_LIST_TYPES = {}));
export var AGENT_INSTANCE_LIST_TYPES;
(function (AGENT_INSTANCE_LIST_TYPES) {
    AGENT_INSTANCE_LIST_TYPES["Owned"] = "owned";
})(AGENT_INSTANCE_LIST_TYPES || (AGENT_INSTANCE_LIST_TYPES = {}));
