import { jsx as _jsx } from "react/jsx-runtime";
// ThemeContext.tsx
import { createContext, useContext, useEffect, useState, } from 'react';
import { themeConfig } from 'src/context/ThemeContext/themeConfig';
const ThemeContext = createContext(undefined);
export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState('dark');
    useEffect(() => {
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme) {
            // TODO: Turn back on dark andlight mode
            // setTheme(savedTheme);
            setTheme('dark');
        }
    }, []);
    useEffect(() => {
        localStorage.setItem('theme', theme);
        document.body.className = `${theme}-mode`;
        const root = document.documentElement;
        const variables = themeConfig[theme];
        Object.keys(variables).forEach((key) => {
            root.style.setProperty(key, variables[key]);
        });
    }, [theme]);
    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
    };
    return (_jsx(ThemeContext.Provider, { value: { theme, toggleTheme }, children: children }));
};
export const useTheme = () => {
    const context = useContext(ThemeContext);
    if (context === undefined) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
};
