import { createSlice } from '@reduxjs/toolkit';
import { SYSTEM_MODALS } from './constants';
const initialState = {
    systemModals: {
        [SYSTEM_MODALS.downloadLauncher]: {
            show: false,
        },
    },
};
export const applicationSlice = createSlice({
    name: 'application',
    initialState,
    reducers: {
        setShowSystemModal: (state, action) => {
            const modalName = action.payload.modalName;
            state.systemModals = Object.assign(Object.assign({}, state.systemModals), { [modalName]: Object.assign(Object.assign({}, state.systemModals[modalName]), { show: action.payload.show }) });
        },
    },
});
export const { setShowSystemModal } = applicationSlice.actions;
export default applicationSlice.reducer;
