var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cn from 'classnames';
import { forwardRef } from 'react';
import style from './styles.module.scss';
const InputField = forwardRef((_a, ref) => {
    var { className = '', label, hasError, errorText, prefixElement, postfixElement } = _a, props = __rest(_a, ["className", "label", "hasError", "errorText", "prefixElement", "postfixElement"]);
    return (_jsxs("div", { className: style.inputWrap, children: [label && _jsx("div", { className: style.inputWrap_label, children: label }), _jsxs("div", { className: style.inputWrap_field, children: [prefixElement && (_jsx("div", { className: style.inputWrap_field_prefix, children: prefixElement })), _jsx("input", Object.assign({ className: cn(style.input, className, {
                            [style.error]: hasError,
                            [style.prefix]: prefixElement,
                            [style.postfix]: postfixElement,
                        }), ref: ref }, props)), postfixElement && (_jsx("div", { className: style.inputWrap_field_postfix, children: postfixElement }))] }), hasError && _jsx("div", { className: style.errorText, children: errorText })] }));
});
InputField.displayName = 'InputField';
export default InputField;
