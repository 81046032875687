var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import Button, { ButtonVariants } from 'src/components/Buttons/Button';
import InputField from 'src/components/InputField';
import { isKnownError } from 'src/helpers';
import style from './styles.module.scss';
import { validationSchema } from './validationSchema';
const InviteCode = ({ actionClick, onClose, currentTIme, }) => {
    var _a;
    const { register, handleSubmit, formState: { errors }, setError, } = useForm({
        resolver: zodResolver(validationSchema),
    });
    const onSubmit = (formData) => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        try {
            yield actionClick(formData);
            onClose();
        }
        catch (error) {
            // Type assertion
            if (isKnownError(error)) {
                if (error.status === 400 && ((_b = error.data) === null || _b === void 0 ? void 0 : _b.message)) {
                    setError('inviteCode', {
                        type: 'manual',
                        message: error.data.message,
                    });
                }
            }
            else {
                console.error('Unexpected error:', error);
            }
        }
    });
    return (_jsx("div", { className: style.modalChild, children: _jsxs("div", { className: style.modalContainer, children: [_jsx("img", { className: style.modalContainer_logo, src: '/assets/images/logo.svg', alt: 'logo' }), _jsx("div", { className: style.modalContainer_title, children: "Insert Invite Code" }), _jsxs("div", { className: style.modalContainer_subTitle, children: ["You have ", currentTIme, " Play-minutes remaining.", _jsx("br", {}), "Get more by redeeming an invite code."] }), _jsx("p", {}), _jsxs("form", { className: style.modalContainer_form, onSubmit: handleSubmit(onSubmit), children: [_jsx("div", { className: style.modalContainer_form_field, children: _jsx(InputField, Object.assign({ type: 'text', placeholder: 'Insert Code', hasError: !!errors.inviteCode, errorText: (_a = errors.inviteCode) === null || _a === void 0 ? void 0 : _a.message }, register('inviteCode'), { className: style.modalContainer_form_form_input })) }), _jsx("div", { className: style.modalContainer_form_action, children: _jsx(Button, { handleButtonClick: () => handleSubmit(onSubmit)(), variant: ButtonVariants.PRIMARY, className: style.modalContainer_form_action_btn, children: "More minutes!" }) })] })] }) }));
};
export default InviteCode;
