export var PAGES;
(function (PAGES) {
    PAGES["Discover"] = "/discover";
    PAGES["MyAgents"] = "/my-agents";
    PAGES["Create"] = "/create";
    PAGES["TOS"] = "/public/TOS";
    PAGES["AgentProto"] = "/blueprint/:agentProtoId";
    PAGES["AgentInstanceChat"] = "/agent/chat/:agentInstanceId";
    PAGES["AgentInstanceProfile"] = "/agent/profile/:agentInstanceId";
})(PAGES || (PAGES = {}));
