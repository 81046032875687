import { combineReducers } from '@reduxjs/toolkit';
import { alteraApi } from 'src/api/rtk';
import agentSlice from './features/agentSlice';
import applicationSlice from './features/applicationSlice';
import userSlice from './features/userSlice';
import workerSlice from './features/workerSlice';
export default combineReducers({
    [alteraApi.reducerPath]: alteraApi.reducer,
    userSlice,
    agentSlice,
    workerSlice,
    application: applicationSlice,
});
