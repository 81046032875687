var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import alteraApi from 'src/api/rtk/alteraApi';
import { handleErrorQuery } from 'src/api/rtk/helpers';
import { transformPayloadToQuery } from 'src/api/rtk/utils';
import { URLS } from 'src/constants/urls';
import { AGENT_INSTANCE_LIST_TYPES, AGENT_LIST_TYPES, setAgentsList, setAgentsPagination, setInstancesList, setInstancesPagination, setSelectedAgent, } from 'src/redux/features/agentSlice';
import { API_BODY_ARGS, API_QUERY_ARGS, HTTP_METHODS } from '../../constants';
import { AGENT_DTAGS, AGENT_TAGS } from './constants';
/**
 * Handles the dispatching of actions related to pagination and agents list.
 * This function is responsible for waiting for the query to fulfill, extracting the necessary
 * data from the response, and then dispatching the appropriate actions to update the Redux store.
 *
 * @param dispatch - The Redux dispatch function used to dispatch actions.
 * @param agentsListType - The type of agents list (e.g., Owned, Top, Trending).
 * @param queryFulfilled - The promise that resolves with the result of the API call.
 * @param args - The pagination arguments such as page number and page size.
 */
const handlePaginationDispatch = (dispatch, agentsListType, queryFulfilled, args) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c;
    try {
        // Wait for the query to fulfill and destructure the necessary data
        const queryResult = yield queryFulfilled;
        const agentsList = queryResult.data.data;
        const pagination = queryResult.data.pagination;
        // Dispatch the actions with the extracted data
        dispatch(setAgentsList({
            agentsListType,
            agents: agentsList,
        }));
        // Dispatch an action to update the pagination information in the Redux store
        dispatch(setAgentsPagination({
            agentsListType, // The type of agents list (e.g., Owned, Top, Trending).
            page: (_a = args.page) !== null && _a !== void 0 ? _a : 1, // The current page number.
            pageSize: (_b = args.pageSize) !== null && _b !== void 0 ? _b : 100, // The number of items per page.
            hasNextPage: (_c = pagination === null || pagination === void 0 ? void 0 : pagination.having_next_page) !== null && _c !== void 0 ? _c : false, // A boolean indicating whether there is a next page.
        }));
    }
    catch (errResp) {
        // Handle any errors that occur during the API call
        handleErrorQuery(errResp);
    }
});
const agentRtk = alteraApi.injectEndpoints({
    endpoints: (build) => ({
        // ARCHIVE AGENT
        // TODO: remove this endpoint
        agentArchiveInstance: build.query({
            query({ agentInstanceId }) {
                const query = transformPayloadToQuery({
                    payload: {
                        [API_QUERY_ARGS.agentId]: agentInstanceId,
                    },
                });
                return { url: `${URLS.agent.archive}${query}` };
            },
            onQueryStarted: (_1, _d) => __awaiter(void 0, [_1, _d], void 0, function* (_, { queryFulfilled, dispatch }) {
                try {
                    yield queryFulfilled;
                    dispatch(alteraApi.util.invalidateTags([AGENT_LIST_TYPES.Owned]));
                }
                catch (errResp) {
                    handleErrorQuery(errResp);
                }
            }),
        }),
        // GET a specific list of Agents
        getAgentList: build.query({
            query({ page = 1, pageSize = 50, nonAuth = false, listType, }) {
                const groupId = listType.toLowerCase();
                const query = transformPayloadToQuery({
                    payload: {
                        [API_QUERY_ARGS.groupId]: groupId,
                        [API_QUERY_ARGS.page]: page,
                        [API_QUERY_ARGS.pageSize]: pageSize,
                    },
                });
                const ApiUrl = nonAuth
                    ? URLS.agent.getAgentsByGroupPublic
                    : URLS.agent.getAgentsByGroup;
                return { url: `${ApiUrl}${query}` };
            },
            onQueryStarted: (args_1, _e) => __awaiter(void 0, [args_1, _e], void 0, function* (args, { dispatch, queryFulfilled }) {
                yield handlePaginationDispatch(dispatch, args.listType, queryFulfilled, args);
            }),
            providesTags: (result, error, arg) => [
                AGENT_TAGS.agentList,
                { type: AGENT_DTAGS.list, id: arg.listType },
            ],
        }),
        getAgentInstanceList: build.query({
            query({ page = 1, pageSize = 10 }) {
                const query = transformPayloadToQuery({
                    payload: {
                        [API_QUERY_ARGS.page]: page,
                        [API_QUERY_ARGS.pageSize]: pageSize,
                    },
                });
                return { url: `${URLS.agent.getInstances}${query}` };
            },
            onQueryStarted: (args_2, _f) => __awaiter(void 0, [args_2, _f], void 0, function* (args, { dispatch, queryFulfilled }) {
                var _g, _h, _j;
                try {
                    const queryResult = yield queryFulfilled;
                    const instancesList = queryResult.data.data;
                    const pagination = queryResult.data.pagination;
                    // Dispatch the actions with the extracted data
                    dispatch(setInstancesList({
                        instancesListType: AGENT_INSTANCE_LIST_TYPES.Owned,
                        instances: instancesList,
                    }));
                    // Dispatch an action to update the pagination information in the Redux store
                    dispatch(setInstancesPagination({
                        instancesListType: AGENT_INSTANCE_LIST_TYPES.Owned,
                        page: (_g = args.page) !== null && _g !== void 0 ? _g : 1,
                        pageSize: (_h = args.pageSize) !== null && _h !== void 0 ? _h : 10,
                        hasNextPage: (_j = pagination === null || pagination === void 0 ? void 0 : pagination.having_next_page) !== null && _j !== void 0 ? _j : false,
                    }));
                }
                catch (errResp) {
                    // Handle any errors that occur during the API call
                    handleErrorQuery(errResp);
                }
            }),
            providesTags: [AGENT_TAGS.instanceList],
        }),
        // GET SEARCHED LIST
        getAgentSearchedList: build.query({
            query({ page = 1, pageSize = 50, nonAuth = false, search, }) {
                const query = transformPayloadToQuery({
                    payload: {
                        [API_QUERY_ARGS.search]: search,
                        [API_QUERY_ARGS.page]: page,
                        [API_QUERY_ARGS.pageSize]: pageSize,
                    },
                });
                const searchApiUrl = nonAuth
                    ? URLS.agent.searchPublic
                    : URLS.agent.search;
                return { url: `${searchApiUrl}${query}` };
            },
            onQueryStarted: (args_3, _k) => __awaiter(void 0, [args_3, _k], void 0, function* (args, { dispatch, queryFulfilled }) {
                yield handlePaginationDispatch(dispatch, AGENT_LIST_TYPES.Searched, queryFulfilled, args);
            }),
            providesTags: () => [
                AGENT_TAGS.agentList,
                { type: AGENT_DTAGS.list, id: AGENT_LIST_TYPES.Searched },
            ],
        }),
        getOwnedAgentList: build.query({
            query({ page = 1, pageSize = 50 }) {
                const query = transformPayloadToQuery({
                    payload: {
                        [API_QUERY_ARGS.page]: page,
                        [API_QUERY_ARGS.pageSize]: pageSize,
                    },
                });
                return { url: `${URLS.agent.getOwnedAgents}${query}` };
            },
            onQueryStarted: (args_4, _l) => __awaiter(void 0, [args_4, _l], void 0, function* (args, { dispatch, queryFulfilled }) {
                yield handlePaginationDispatch(dispatch, AGENT_LIST_TYPES.Owned, queryFulfilled, args);
            }),
            providesTags: () => [
                AGENT_TAGS.agentList,
                { type: AGENT_DTAGS.list, id: AGENT_LIST_TYPES.Owned },
            ],
        }),
        // GET AGENT PROTO
        getAgentProto: build.query({
            query({ agentProtoId }) {
                const query = transformPayloadToQuery({
                    payload: {
                        [API_QUERY_ARGS.agentProtoId]: agentProtoId,
                    },
                });
                return { url: `${URLS.agent.get}${query}` };
            },
            onQueryStarted: (_2, _m) => __awaiter(void 0, [_2, _m], void 0, function* (_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = yield queryFulfilled;
                    dispatch(setSelectedAgent(data));
                }
                catch (errResp) {
                    handleErrorQuery(errResp);
                }
            }),
            providesTags: [AGENT_TAGS.agentEntity],
        }),
        getAgentInstanceById: build.query({
            query(agentInstanceId) {
                const query = transformPayloadToQuery({
                    payload: {
                        [API_QUERY_ARGS.agentInstanceId]: agentInstanceId,
                    },
                });
                return { url: `${URLS.agent.getInstanceById}${query}` };
            },
            transformResponse: (response) => response.data,
            providesTags: (result, error, agentInstanceId) => [
                { type: AGENT_TAGS.instanceEntity, id: agentInstanceId },
            ],
        }),
        getInstancesStatusByAgentId: build.query({
            query(agentId) {
                const query = transformPayloadToQuery({
                    payload: {
                        [API_QUERY_ARGS.agentId]: agentId,
                    },
                });
                return { url: `${URLS.agent.getInstanceStatusByAgentId}${query}` };
            },
            transformResponse: (response) => (response === null || response === void 0 ? void 0 : response.data) || [],
        }),
        getAgentInstanceHistory: build.query({
            query(agentInstanceId) {
                const query = transformPayloadToQuery({
                    payload: {
                        [API_QUERY_ARGS.agentInstanceId]: agentInstanceId,
                    },
                });
                return { url: `${URLS.agent.getInstanceHistory}${query}` };
            },
            transformResponse: (response) => {
                return response.data.sort((a, b) => {
                    const dateA = new Date(a.last_updated_time).getTime();
                    const dateB = new Date(b.last_updated_time).getTime();
                    return dateB - dateA; // Sort in descending order (most recent first)
                });
            },
            providesTags: (result, error, agentInstanceId) => [
                { type: AGENT_TAGS.instanceHistory, id: agentInstanceId },
            ],
        }),
        getAgentSkinById: build.query({
            query(agentId) {
                const query = transformPayloadToQuery({
                    payload: {
                        [API_QUERY_ARGS.agentId]: agentId,
                    },
                });
                return {
                    url: `${URLS.agent.getAgentSkins}${query}`,
                    responseHandler: (response) => response.blob(),
                };
            },
        }),
        getAgentGroups: build.query({
            query({ nonAuth }) {
                const ApiUrl = nonAuth ? URLS.agent.groupsPublic : URLS.agent.groups;
                return { url: `${ApiUrl}` };
            },
            transformResponse: (response) => response.data,
        }),
        // Save chat message for agent instance chat
        saveAgentInstanceChatMessage: build.mutation({
            query({ agent_instance_id, message, isUserMessage, }) {
                return {
                    url: `${URLS.agent.directChatInstanceSaveMessage}`,
                    method: HTTP_METHODS.POST,
                    body: {
                        [API_BODY_ARGS.agentInstanceId]: agent_instance_id,
                        [API_BODY_ARGS.content]: message,
                        [API_BODY_ARGS.isUserMessage]: isUserMessage,
                    },
                };
            },
        }),
        // Add chat message for agent creation
        addCreatorChatMessage: build.mutation({
            query({ message }) {
                return {
                    url: URLS.agent_creation.chat,
                    method: HTTP_METHODS.POST,
                    body: { message },
                };
            },
        }),
        // Signal the end of the conversation
        finishConversation: build.mutation({
            query() {
                return {
                    url: URLS.agent_creation.finishChat,
                    method: HTTP_METHODS.POST,
                };
            },
            onQueryStarted: (arg_1, _o) => __awaiter(void 0, [arg_1, _o], void 0, function* (arg, { queryFulfilled }) {
                try {
                    yield queryFulfilled;
                }
                catch (errResp) {
                    handleErrorQuery(errResp);
                }
            }),
            invalidatesTags: () => [
                AGENT_TAGS.agentEntity,
                { type: AGENT_DTAGS.list, id: AGENT_LIST_TYPES.Owned },
            ],
        }),
        // Get chat from the agent creation
        getDirectChatInstanceMessages: build.query({
            query(props) {
                const { agent_instance_id, page, pageSize } = props;
                const query = transformPayloadToQuery({
                    payload: Object.assign(Object.assign({ [API_QUERY_ARGS.agentInstanceId]: agent_instance_id }, (page ? { [API_QUERY_ARGS.page]: page } : {})), (pageSize ? { [API_QUERY_ARGS.pageSize]: pageSize } : {})),
                });
                return {
                    url: `${URLS.agent.directChatInstanceGetChat}${query}`,
                    method: HTTP_METHODS.GET,
                };
            },
            transformResponse: (response) => response,
        }),
        // Get chat from the agent creation
        getCreatorChatMessages: build.query({
            query(arg = undefined) {
                return {
                    url: URLS.agent_creation.getChat,
                    method: HTTP_METHODS.GET,
                };
            },
        }),
        // Updated endpoint for starting an agent
        fetchAgentInstances: build.mutation({
            query(payload) {
                return {
                    url: URLS.agent.fetchAgentInstances,
                    method: 'POST',
                    body: payload,
                };
            },
        }),
        // ATTACH AGENT TO SPECIFIC GAME (BRANCH)
        agentAddToGame: build.query({
            query({ agentId, gameId }) {
                const query = transformPayloadToQuery({
                    payload: {
                        [API_QUERY_ARGS.agentId]: agentId,
                        [API_QUERY_ARGS.gameId]: gameId,
                    },
                });
                return { url: `${URLS.agent.copy}${query}` };
            },
        }),
        // ARCHIVE AGENT PROTO
        archiveAgentProto: build.mutation({
            query(agentProtoId) {
                const query = transformPayloadToQuery({
                    payload: {
                        [API_QUERY_ARGS.agentProtoId]: agentProtoId,
                    },
                });
                return {
                    url: `${URLS.agent.archive}${query}`,
                    method: HTTP_METHODS.GET, // The backend uses GET for this endpoint
                };
            },
            invalidatesTags: (result, error, agentProtoId) => [
                { type: AGENT_DTAGS.list, id: AGENT_LIST_TYPES.Owned },
                { type: AGENT_TAGS.agentEntity, id: agentProtoId },
            ],
        }),
        // ARCHIVE AGENT INSTANCE
        archiveAgentInstance: build.mutation({
            query(agentInstanceId) {
                const query = transformPayloadToQuery({
                    payload: {
                        [API_QUERY_ARGS.agentInstanceId]: agentInstanceId,
                    },
                });
                return {
                    url: `${URLS.agent.archiveInstance}${query}`,
                    method: HTTP_METHODS.GET, // The backend uses GET for this endpoint
                };
            },
            invalidatesTags: (result, error, agentInstanceId) => [
                AGENT_TAGS.instanceList,
                { type: AGENT_TAGS.instanceEntity, id: agentInstanceId },
            ],
        }),
        // Check if an agent instance exists for a given user and agent prototype
        checkAgentInstanceExists: build.query({
            query: ({ agentProtoId }) => ({
                url: URLS.agent.checkInstanceExists,
                method: HTTP_METHODS.GET,
                params: { agent_proto_id: agentProtoId },
            }),
            transformResponse: (response) => response.data,
            transformErrorResponse: (response) => {
                if (response.status === 404) {
                    return null;
                }
                throw response;
            },
        }),
    }),
});
export const { useGetAgentListQuery, useGetOwnedAgentListQuery, useGetAgentInstanceListQuery, useGetAgentSearchedListQuery, useGetAgentProtoQuery, useAddCreatorChatMessageMutation, useSaveAgentInstanceChatMessageMutation, useFinishConversationMutation, useGetCreatorChatMessagesQuery, useGetAgentGroupsQuery, useGetDirectChatInstanceMessagesQuery, useGetAgentInstanceByIdQuery, useGetAgentInstanceHistoryQuery, useGetAgentSkinByIdQuery, useFetchAgentInstancesMutation, useGetInstancesStatusByAgentIdQuery, useArchiveAgentProtoMutation, useArchiveAgentInstanceMutation, useCheckAgentInstanceExistsQuery, } = agentRtk;
