import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AGENT_TAGS } from './services/agent/constants';
import { USER_TAGS } from './services/user/constants';
import { WORKER_TAGS } from './services/worker/constants';
const alteraApi = createApi({
    reducerPath: 'alteraApi',
    baseQuery: fetchBaseQuery(),
    refetchOnMountOrArgChange: true,
    tagTypes: [
        ...Object.values(AGENT_TAGS),
        ...Object.values(USER_TAGS),
        ...Object.values(WORKER_TAGS),
    ],
    endpoints: () => ({}),
});
export default alteraApi;
