import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cn from 'classnames';
import Image from 'src/components/Images/Image';
import style from './styles.module.scss';
export var AvatarSize;
(function (AvatarSize) {
    AvatarSize["full"] = "full";
    AvatarSize["small"] = "small";
    AvatarSize["mini"] = "mini";
})(AvatarSize || (AvatarSize = {}));
export var AvatarStatus;
(function (AvatarStatus) {
    AvatarStatus["online"] = "online";
    AvatarStatus["offline"] = "offline";
})(AvatarStatus || (AvatarStatus = {}));
const Avatar = ({ image, name, alt, avatarSize = AvatarSize.full, avatarStatus, }) => {
    const getInitials = (name) => {
        if (!name)
            return '';
        const nameArray = name.split(' ');
        const initials = nameArray.map((part) => part.charAt(0)).join('');
        return initials.toUpperCase();
    };
    return (_jsxs("div", { className: cn(style.avatar, {
            [style.avatar_full]: avatarSize === AvatarSize.full,
            [style.avatar_small]: avatarSize === AvatarSize.small,
            [style.avatar_mini]: avatarSize === AvatarSize.mini,
        }), children: [image ? (_jsx(Image, { src: image, alt: alt !== null && alt !== void 0 ? alt : 'avatar', className: style.avatar_image })) : (_jsx("div", { className: style.avatarFallback, children: getInitials(name) })), avatarStatus && (_jsx("div", { className: cn(style.status, {
                    [style.status_online]: avatarStatus === AvatarStatus.online,
                }) }))] }));
};
export default Avatar;
