import { useEffect } from 'react';
import { useCreateUserApiKeyMutation, useGetCurrentUserApiKeyQuery, } from 'src/api/rtk/services/user';
import { useIsUserAuthenticated } from 'src/hooks/user';
const useGetCurrentUserApiKey = () => {
    const isUserAuthenticated = useIsUserAuthenticated();
    const [createApiKey] = useCreateUserApiKeyMutation();
    // TRY TO GET USER'S API KEY
    const hookResult = useGetCurrentUserApiKeyQuery({}, {
        skip: !isUserAuthenticated,
    });
    // CREATE API KEY IF NOT KEY FOUND
    useEffect(() => {
        var _a;
        if (hookResult.status !== 'fulfilled')
            return;
        const apiKeysData = ((_a = hookResult.data) === null || _a === void 0 ? void 0 : _a.data) || [];
        if (apiKeysData.length)
            return;
        createApiKey({});
    }, [hookResult, createApiKey]);
    return hookResult;
};
export default useGetCurrentUserApiKey;
