import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    auth: null,
    socialAuth: null,
    profile: null,
    apiKey: null,
    socialMedia: null,
    invitationError: '',
};
export const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        setSocialAuth: (state, action) => {
            state.socialAuth = action.payload;
        },
        logout: (state) => {
            state.auth = null;
            state.socialAuth = null;
            state.profile = null;
            state.apiKey = null;
        },
        setInvitationError: (state, action) => {
            state.invitationError = action.payload;
        },
        setUserProfile: (state, action) => {
            state.profile = action.payload.profile;
            if (action.payload.socialMedia) {
                state.socialMedia = action.payload.socialMedia;
            }
        },
        setUserApiKey: (state, action) => {
            var _a;
            state.apiKey = (_a = action.payload) !== null && _a !== void 0 ? _a : null;
        },
        startAuthSession: (state, _) => {
            var _a;
            state.auth = Object.assign(Object.assign({}, ((_a = state.auth) !== null && _a !== void 0 ? _a : {})), { sessionExpiresAt: new Date(Date.now() + (24 * 60 * 60 * 1000 - 60 * 1000)).toISOString() });
        },
        refreshAuthSession: (state, action) => {
            state.auth = Object.assign(Object.assign({}, state.auth), { sessionExpiresAt: action.payload.expiresAt ||
                    new Date(Date.now() + (24 * 60 * 60 * 1000 - 60 * 1000)).toISOString() });
        },
    },
});
export const { setSocialAuth, startAuthSession, refreshAuthSession, logout, setInvitationError, setUserProfile, setUserApiKey, } = userSlice.actions;
export default userSlice.reducer;
