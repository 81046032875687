export const HTTP_METHODS = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    PATCH: 'PATCH',
    DELETE: 'DELETE',
};
export const API_QUERY_ARGS = {
    page: 'page',
    pageSize: 'per_page',
    groupId: 'group_id',
    agentId: 'agent_id', // agentId should only be used when the function works for both proto and instance
    agentProtoId: 'agent_proto_id',
    agentInstanceId: 'agent_instance_id',
    gameInstanceId: 'game_instance_id',
    gameId: 'game_id',
    search: 'search',
};
export const API_BODY_ARGS = {
    agentInstanceId: 'agent_instance_id',
    content: 'content',
    isUserMessage: 'is_user_message',
};
