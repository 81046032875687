import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectGlobalFilterSearch, setGlobalFilters, } from 'src/redux/features/agentSlice';
import { PAGES } from 'src/router/pages';
const useSearchHandlers = ({ dispatch, searchValue }) => {
    const navigate = useNavigate();
    const searchValueFromStore = useSelector(selectGlobalFilterSearch);
    const onSearchBlurHandler = () => {
        if (searchValueFromStore !== searchValue) {
            applyGlobalSearch();
        }
    };
    const onSearchKeyDown = (event) => {
        if (event.key === 'Enter' && searchValueFromStore !== searchValue) {
            applyGlobalSearch();
        }
    };
    const applyGlobalSearch = () => {
        dispatch(setGlobalFilters({ search: searchValue }));
        if (window.location.pathname !== PAGES.Discover) {
            navigate(PAGES.Discover);
        }
    };
    return {
        onBlur: onSearchBlurHandler,
        onKeyDown: onSearchKeyDown,
    };
};
export default useSearchHandlers;
