export const handleUpgradeSubscription = (productName = 'premium') => {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = '/api/v1/stripe/checkout';
    const hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.name = 'product_name';
    hiddenField.value = productName;
    form.appendChild(hiddenField);
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
};
