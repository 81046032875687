import { createSlice } from '@reduxjs/toolkit';
import { AGENT_INSTANCE_LIST_TYPES, AGENT_LIST_TYPES } from './constants';
const getInitialPagination = () => {
    return {
        page: 1,
        pageSize: 50,
        hasNextPage: false,
        hasPrevPage: false,
    };
};
const preparePaginationState = ({ paginationState, page, pageSize, hasNextPage, }) => {
    return Object.assign(Object.assign({}, paginationState), { page: page, pageSize: pageSize !== null && pageSize !== void 0 ? pageSize : paginationState.pageSize, hasNextPage: hasNextPage !== null && hasNextPage !== void 0 ? hasNextPage : paginationState.hasNextPage, hasPrevPage: Boolean(page > 1) });
};
const initialState = {
    selectedAgent: {
        instance: null,
    },
    selectedInstance: null,
    agentLists: {
        [AGENT_LIST_TYPES.Featured]: {
            list: [],
            pagination: getInitialPagination(),
        },
        [AGENT_LIST_TYPES.CommunityFavourite]: {
            list: [],
            pagination: getInitialPagination(),
        },
        [AGENT_LIST_TYPES.Trending]: {
            list: [],
            pagination: getInitialPagination(),
        },
        [AGENT_LIST_TYPES.Owned]: {
            list: [],
            pagination: getInitialPagination(),
        },
        [AGENT_LIST_TYPES.Searched]: {
            list: [],
            pagination: getInitialPagination(),
        },
    },
    instances: {
        [AGENT_INSTANCE_LIST_TYPES.Owned]: {
            list: [],
            pagination: getInitialPagination(),
        },
    },
    globalFilters: {
        search: '',
    },
};
export const agentSlice = createSlice({
    name: 'agentSlice',
    initialState,
    reducers: {
        setSelectedAgent: (state, action) => {
            state.selectedAgent = Object.assign(Object.assign({}, state.selectedAgent), { instance: action.payload.agent });
        },
        setSelectedInstance: (state, action) => {
            state.selectedInstance = action.payload;
        },
        setAgentsList: (state, action) => {
            const agentsListType = action.payload.agentsListType;
            const agentsList = action.payload.agents;
            state.agentLists = Object.assign(Object.assign({}, state.agentLists), { [agentsListType]: Object.assign(Object.assign({}, state.agentLists[agentsListType]), { list: agentsList }) });
        },
        setAgentsPagination: (state, action) => {
            var _a;
            const agentsListType = action.payload.agentsListType;
            const pageValue = (_a = action.payload.page) !== null && _a !== void 0 ? _a : state.agentLists[agentsListType].pagination.page;
            //
            state.agentLists = Object.assign(Object.assign({}, state.agentLists), { [agentsListType]: Object.assign(Object.assign({}, state.agentLists[agentsListType]), { pagination: Object.assign({}, preparePaginationState({
                        paginationState: state.agentLists[agentsListType].pagination,
                        page: pageValue,
                        pageSize: action.payload.pageSize,
                        hasNextPage: action.payload.hasNextPage,
                    })) }) });
        },
        setInstancesList: (state, action) => {
            const instancesListType = action.payload.instancesListType;
            const instancesList = action.payload.instances;
            state.instances = Object.assign(Object.assign({}, state.instances), { [instancesListType]: Object.assign(Object.assign({}, state.instances[instancesListType]), { list: instancesList }) });
        },
        setInstancesPagination: (state, action) => {
            var _a;
            const instancesListType = action.payload.instancesListType;
            const pageValue = (_a = action.payload.page) !== null && _a !== void 0 ? _a : state.instances[instancesListType].pagination.page;
            state.instances = Object.assign(Object.assign({}, state.instances), { [instancesListType]: Object.assign(Object.assign({}, state.instances[instancesListType]), { pagination: Object.assign({}, preparePaginationState({
                        paginationState: state.instances[instancesListType].pagination,
                        page: pageValue,
                        pageSize: action.payload.pageSize,
                        hasNextPage: action.payload.hasNextPage,
                    })) }) });
        },
        setGlobalFilters: (state, action) => {
            var _a;
            state.globalFilters = Object.assign(Object.assign({}, state.globalFilters), { search: (_a = action.payload.search) !== null && _a !== void 0 ? _a : state.globalFilters.search });
        },
    },
});
export const { setSelectedAgent, setAgentsList, setAgentsPagination, setInstancesList, setInstancesPagination, setGlobalFilters, setSelectedInstance, } = agentSlice.actions;
export default agentSlice.reducer;
