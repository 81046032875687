import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { lazy, Suspense } from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import MainLayout from 'src/layout/mainLayout';
import { ProtectedRoute } from 'src/router/Route';
import { PAGES } from './pages';
const DiscoverPage = lazy(() => import('src/pages/public/DiscoverPage'));
const MyAgentsPage = lazy(() => import('src/pages/public/MyAgentsPage'));
const AgentInstanceChatPage = lazy(() => import('src/pages/public/AgentInstanceChatPage'));
const AgentInstanceProfilePage = lazy(() => import('src/pages/public/AgentInstanceProfilePage'));
const AgentProtoPage = lazy(() => import('src/pages/public/AgentProtoPage'));
const CreateAgentPage = lazy(() => import('src/pages/public/CreateAgentPage'));
const TOSPage = lazy(() => import('src/pages/public/TOS/tos'));
export const router = createBrowserRouter([
    {
        path: '/',
        element: _jsx(MainLayout, {}),
        errorElement: _jsx("h1", { children: "Error" }),
        children: [
            {
                index: true,
                element: _jsx(Navigate, { to: PAGES.Discover, replace: true }),
            },
            {
                path: PAGES.Discover,
                element: (_jsx(Suspense, { fallback: _jsx("div", { children: "Loading Discover page..." }), children: _jsx(DiscoverPage, {}) })),
            },
            {
                path: PAGES.MyAgents,
                element: _jsx(ProtectedRoute, { component: MyAgentsPage }),
            },
            {
                path: PAGES.AgentInstanceChat,
                element: _jsx(ProtectedRoute, { component: AgentInstanceChatPage }),
            },
            {
                path: PAGES.AgentInstanceProfile,
                element: _jsx(ProtectedRoute, { component: AgentInstanceProfilePage }),
            },
            {
                path: PAGES.Create,
                element: _jsx(ProtectedRoute, { component: CreateAgentPage }),
            },
            {
                path: PAGES.TOS,
                element: (_jsx(Suspense, { fallback: _jsx("div", { children: "Loading TOS page..." }), children: _jsx(TOSPage, {}) })),
            },
            {
                path: PAGES.AgentProto,
                element: _jsx(ProtectedRoute, { component: AgentProtoPage }),
            },
            // Add a catch-all route for debugging
            {
                path: '*',
                element: (_jsxs("div", { children: ["Catch-all: No route matched. Attempted path:", ' ', window.location.pathname] })),
            },
        ],
    },
]);
export default router;
