/**
 * Theme Configuration
 * Defines color schemes for app themes.
 * Only dark mode active; light mode disabled.
 * Light mode variables present can be blank.
 */
// themeConfig.ts
export const themeConfig = {
    light: {
        // PRIMARY COLORS
        '--primary-light-1': '#FFFFFF',
        '--light-2': '#ddd',
        '--grey-2': '#333333',
        '--grey-3': '#888888',
        // ALL
        '--spot-color-1': '#62D998',
        '--spot-color-2': '#ffffff',
        // INPUT FIELD
        '--input-bg': '#EFF5EF',
        '--question-bg': '#C3D4C3',
        '--input-color': '#68786A',
        '--input-border': '#C3D4C3',
        '--input-shadow': '0 0 6px 0 rgba(54, 57, 53, 0.25), 0 0 10px 0 rgba(54, 57, 53, 0.15)',
        // MODAL
        '--modal-overlay-bg': 'rgba(249, 253, 249, 0.1)',
        '--modal-border': '#8AA090',
        '--modal-bg': 'rgba(239, 245, 239, 0.95)',
        '--modal-title': '#373F38',
        // MAIN
        '--background-color': '#F9FDF9',
        '--text-color': '#373F38',
        '--primary-color': '#37A742',
        '--header-footer-shadow': '#DEDEDE',
        '--header-footer-border': '#8AA090',
        '--accent-color': '#68786A',
        '--feature-color': '#edf3ed',
        '--border-color': '#7EAC7E',
        '--border-color-2': '#A0A0A0',
        // HEADER
        '--minutes-color': '#373F38',
        '--search-input-bg': '#EFF5EF',
        '--search-input-color': '#68786A',
        '--search-input-border': '#C3D4C3',
        // AGENTS
        '--title-color': '#373F38',
        '--description-color': '#68786A',
        // MENU
        '--menu-border': '#7EAC7E',
        '--menu-color': '#373F38',
        '--menu-active-color': '#133F18',
        '--menu-active-color-2': '#3B413C',
        '--menu-active-bg': '#C3D4C3',
        '--menu-user-email': '#68786A',
        '--avatar-border': '#5BC875',
        '--test-color-1': '#FF00FF',
        // CARD
        '--card-bg-1': 'rgba(242, 247, 242, 0.7)',
        '--card-bg-2': 'rgba(132, 146, 134, 0.8)',
        // CONTENT
        '--content-background': '#F9FDF9',
        // TEXT AND BUTTON BACKGROUND
        '--text-bg': '#FFFFFF',
        '--popup-bg-color': '#EDEDED',
        '--text-color-secondary': '#28282B',
        '--text-color-tertiary': 'rgba(0, 0, 0, 0.5)',
        '--round-bg-color': '#3D3D3D',
        '--hover-color-1': '#121212',
        '--hover-color-2': '#62D998',
        // CHAT
        '--chat-background': 'linear-gradient(135deg, #F9FDF9, #212121, #1c1c1c, #232323, #1e1e1e)',
        '--chat-bg': '#E9E9EB',
        '--chat-input-bg': 'rgba(255, 255, 255, 0.8)',
        '--chat-input-focus': 'rgba(255, 255, 255, 1)',
        '--chat-text-bg': '#0b93f6',
        '--chat-text-bg-2': '#0056b3',
        // TOS
        '--tos-primary-bg': '#121212',
        '--tos-secondary-bg': '#ffffff',
        '--tos-secondary-color': 'rgba(104, 120, 106, 0.3)',
        '--tos-text-color': '#68786A',
        '--tos-accent-color': '#3a7bd5',
        '--tos-border-color': 'rgba(104, 120, 106, 0.08)',
        '--tos-title-color': '#373F38',
    },
    dark: {
        // PRIMARY COLORS
        '--primary-light-1': '#FFFFFF',
        '--light-2': '#ddd',
        '--grey-2': '#333333',
        '--grey-3': '#888888',
        // ALL
        '--spot-color-1': '#FFFFFF',
        '--spot-color-2': '#ffffff',
        // INPUT FIELD
        '--input-bg': 'linear-gradient(0deg, rgba(42, 42, 42, 0.00) 0%, rgba(58, 58, 58, 0.40) 100%), #3A3A3A',
        '--input-color': '#A0A0A0',
        '--input-border': '#555555',
        '--input-shadow': '0px 2px 0px 0px #252525',
        '--question-bg': '#555555',
        // MODAL
        '--modal-overlay-bg': 'rgba(26, 26, 26, 0.80)',
        '--modal-border': '#555555',
        '--modal-bg': 'linear-gradient(0deg, rgba(42, 42, 42, 0.00) 0%, rgba(58, 58, 58, 0.40) 100%), rgba(42, 42, 42, 0.50)',
        '--modal-title': '#C0C0C0',
        // MAIN
        '--background-color': '#1A1A1A',
        '--text-color': '#A0A0A0',
        '--primary-color': '#4A4A4A',
        '--header-footer-shadow': '#333333',
        '--header-footer-border': '#333333',
        '--accent-color': '#E0E0E0',
        '--feature-color': '#E0E0E0',
        '--border-color': '#555555',
        '--border-color-2': '#FFFFFF',
        // HEADER
        '--minutes-color': '#E0E0E0',
        '--search-input-bg': 'linear-gradient(0deg, rgba(42, 42, 42, 0.00) 0%, rgba(58, 58, 58, 0.40) 100%), #3A3A3A',
        '--search-input-color': '#A0A0A0',
        '--search-input-border': '#555555',
        // AGENTS
        '--title-color': '#E0E0E0',
        '--description-color': '#B0B0B0',
        // MENU
        '--menu-border': '#555555',
        '--menu-color': '#A0A0A0',
        '--menu-active-color': '#62D998',
        '--menu-active-color-2': '#E0E0E0',
        '--menu-active-bg': 'linear-gradient(0deg, rgba(42, 42, 42, 0.00) 0%, rgba(58, 58, 58, 0.40) 100%), #3A3A3A',
        '--menu-user-email': '#808080',
        '--avatar-border': '#5BC875',
        '--test-color-1': '#FF00FF',
        // CARD
        '--card-bg-1': 'rgba(37, 37, 37, 0.0)',
        '--card-bg-2': 'rgba(37, 37, 37, 0.0)',
        // CONTENT
        '--content-background': '#1A1A1A',
        // TEXT AND BUTTON BACKGROUND
        '--popup-bg-color': '#121212',
        '--text-bg': '#3D3D3D',
        '--text-color-secondary': '#FFFFFF',
        '--text-color-tertiary': 'rgba(255, 255, 255, 0.5)',
        '--round-bg-color': '#3D3D3D',
        '--hover-color-1': '#62D998',
        '--hover-color-2': '#62D998',
        // CHAT
        '--chat-background': 'linear-gradient(135deg, #1A1A1A, #212121, #1c1c1c, #232323, #1e1e1e)',
        '--chat-bg': '#3b3b3b',
        '--chat-input-bg': ' rgba(255, 255, 255, 0.1)',
        '--chat-input-focus': 'rgba(255, 255, 255, 0.2)',
        '--chat-text-bg': '#0b93f6',
        '--chat-text-bg-2': '#0056b3',
        // TOS
        '--tos-primary-bg': '#121212',
        '--tos-secondary-bg': '#1a1a1a',
        '--tos-secondary-color': 'rgba(255, 255, 255, 0.3)',
        '--tos-text-color': '#e0e0e0',
        '--tos-accent-color': '#3a7bd5',
        '--tos-border-color': 'rgba(255, 255, 255, 0.08)',
        '--tos-title-color': '#ffffff',
    },
};
