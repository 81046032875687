export const AGENT_TAGS = {
    agentList: 'agentTagAgentsList',
    agentEntity: 'agentTagAgentEntity',
    instanceList: 'agentTagInstancesList',
    instanceEntity: 'agentTagInstanceEntity',
    instanceHistory: 'agentInstanceHistory',
};
// AGENT DYNAMIC TAGS
export const AGENT_DTAGS = {
    list: 'DTAgentsList',
};
