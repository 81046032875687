import { jsx as _jsx } from "react/jsx-runtime";
import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { PAGES } from 'src/router/pages';
// Define the withAuth function with display name for debugging
const withAuth = (Component) => {
    const ComponentWithAuth = (props) => {
        const user = useSelector((state) => state.userSlice.profile);
        if (!user) {
            return _jsx(Navigate, { to: PAGES.Discover });
        }
        return (_jsx(Suspense, { fallback: _jsx("div", { children: "Loading protected page..." }), children: _jsx(Component, Object.assign({}, props)) }));
    };
    // Set a display name for the component for better debugging
    ComponentWithAuth.displayName = `WithAuth(${Component.displayName || Component.name || 'Component'})`;
    return ComponentWithAuth;
};
export default withAuth;
