import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { mixpanelClient } from 'src/services/mixpanel';
import { selectUserProfile } from 'src/redux/features/userSlice';
const useMixpanelService = () => {
    const userProfile = useSelector(selectUserProfile);
    useEffect(() => {
        if (userProfile) {
            mixpanelClient.identify({
                userId: (userProfile === null || userProfile === void 0 ? void 0 : userProfile.id) || '',
                userAccessTier: (userProfile === null || userProfile === void 0 ? void 0 : userProfile.access_tier) || '',
                userLastLogin: (userProfile === null || userProfile === void 0 ? void 0 : userProfile.last_login) || '',
            });
        }
        return () => {
            if (userProfile)
                mixpanelClient.reset();
        };
    }, [userProfile]);
};
export default useMixpanelService;
