import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
const AuthModalContext = createContext(undefined);
export const AuthModalProvider = ({ children, }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const user = useSelector((state) => state.userSlice.profile);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const shouldShowModal = !user;
    return (_jsx(AuthModalContext.Provider, { value: { isModalOpen, openModal, closeModal, shouldShowModal }, children: children }));
};
export const useAuthModal = () => {
    const context = useContext(AuthModalContext);
    if (context === undefined) {
        throw new Error('useAuthModal must be used within an AuthModalProvider');
    }
    return context;
};
