export const handleErrorQuery = (errorResponse, config) => {
    var _a, _b;
    const showInConsole = (_a = config === null || config === void 0 ? void 0 : config.showInConsole) !== null && _a !== void 0 ? _a : true;
    const queryError = errorResponse === null || errorResponse === void 0 ? void 0 : errorResponse.error;
    let errorMsg = '';
    if (!queryError) {
        errorMsg = 'Unknown error occurred';
    }
    else {
        errorMsg = queryError.status
            ? `Error ${queryError.status}: ${String(((_b = queryError.data) === null || _b === void 0 ? void 0 : _b.message) || queryError.data)}`
            : 'Unknown error occurred';
    }
    if (showInConsole)
        console.error(errorMsg);
};
