// ... existing imports ...
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRefreshSessionMutation } from 'src/api/rtk/services/user/userRtk';
import { selectAuthSessionExpiresAt } from 'src/redux/features/userSlice';
const REFRESH_THRESHOLD = 60 * 60 * 1000; // 1 hour
const useIsUserAuthenticated = () => {
    const authSessionExpiresAt = useSelector(selectAuthSessionExpiresAt);
    const [refreshSession] = useRefreshSessionMutation();
    useEffect(() => {
        const checkAndRefreshSession = () => {
            if (!authSessionExpiresAt)
                return;
            const expiresAt = new Date(authSessionExpiresAt);
            const now = new Date();
            const timeUntilExpiration = expiresAt.getTime() - now.getTime();
            if (timeUntilExpiration > 0 && timeUntilExpiration <= REFRESH_THRESHOLD) {
                refreshSession({});
            }
        };
        checkAndRefreshSession();
        const intervalId = setInterval(checkAndRefreshSession, REFRESH_THRESHOLD); // Check every hour
        return () => clearInterval(intervalId);
    }, [authSessionExpiresAt, refreshSession]);
    return !!authSessionExpiresAt && new Date() < new Date(authSessionExpiresAt);
};
export default useIsUserAuthenticated;
