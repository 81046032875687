import classnames from 'classnames';
import style from 'src/components/Modals/Modal/styles.module.scss';
const useClasses = ({ classes }) => {
    const modalOverlayClass = (classes === null || classes === void 0 ? void 0 : classes.modalOverlay) || '';
    const modalOverlayStyles = classnames({
        [style.modalOverlay]: true,
        [modalOverlayClass]: Boolean(modalOverlayClass),
    });
    const modalContentClass = (classes === null || classes === void 0 ? void 0 : classes.modalContent) || '';
    const modalContentStyles = classnames({
        [style.modalContent]: true,
        [modalContentClass]: Boolean(modalContentClass),
    });
    const contentTitleClass = (classes === null || classes === void 0 ? void 0 : classes.contentTitle) || '';
    const contentTitleStyles = classnames({
        [style.contentTitle]: true,
        [contentTitleClass]: Boolean(contentTitleClass),
    });
    const contentWrapperClass = (classes === null || classes === void 0 ? void 0 : classes.contentWrapper) || '';
    const contentWrapperStyles = classnames({
        [style.contentWrapper]: true,
        [contentWrapperClass]: Boolean(contentWrapperClass),
    });
    return {
        modalOverlay: modalOverlayStyles,
        modalContent: modalContentStyles,
        contentTitle: contentTitleStyles,
        contentWrapper: contentWrapperStyles,
    };
};
export default useClasses;
