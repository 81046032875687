import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import Button, { ButtonVariants } from 'src/components/Buttons/Button';
import Modal from 'src/components/Modals';
import style from './styles.module.scss';
const LoginModal = ({ isOpen, onClose, buildUrl, googleLogin, }) => {
    if (!isOpen)
        return null;
    const user = useSelector((state) => state.userSlice.profile);
    return (_jsx(Modal, { open: isOpen && !user, onClose: onClose, children: _jsx("div", { className: style.loginContainer, children: _jsxs("div", { className: style.modalContainer, children: [_jsxs("div", { className: style.imageContainer, children: [_jsx("img", { src: '/assets/images/LoginSampleImage1.png', alt: 'Image 1' }), _jsx("img", { src: '/assets/images/LoginSampleImage2.png', alt: 'Image 2' }), _jsx("img", { src: '/assets/images/LoginSampleImage3.png', alt: 'Image 3' }), _jsx("img", { src: '/assets/images/LoginSampleImage4.png', alt: 'Image 4' })] }), _jsxs("div", { className: style.modalContent, children: [_jsx("div", { className: style.modalContainer_subTitle, children: "Come play with Altera friends" }), _jsxs("div", { className: style.modalContainer_loginButton, children: [_jsx(Button, { handleButtonClick: () => {
                                            window.location.href = buildUrl();
                                        }, variant: ButtonVariants.PRIMARY, contentClassName: style.modalContainer_loginButton_contentWrap, children: _jsxs("div", { className: style.modalContainer_loginButton_contentWrap_content, children: [_jsx("img", { src: '/assets/images/discord.svg', alt: 'Discord logo' }), _jsx("div", { className: style.text, children: "Login with Discord" })] }) }), _jsx(Button, { handleButtonClick: () => {
                                            googleLogin();
                                        }, variant: ButtonVariants.PRIMARY, contentClassName: style.modalContainer_loginButton_contentWrap, children: _jsxs("div", { className: style.modalContainer_loginButton_contentWrap_content, children: [_jsx("img", { src: '/assets/images/google.svg', alt: 'Google logo' }), _jsx("div", { className: style.text, children: "Login with Google" })] }) })] })] })] }) }) }));
};
export default LoginModal;
