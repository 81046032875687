import { PAGES } from 'src/router/pages';
export const sideBarMokData = [
    {
        linkName: 'Create',
        linkIcon: '/assets/images/plus4.svg',
        navigateToPage: PAGES.Create,
    },
    {
        linkName: 'Discover',
        linkIcon: '/assets/images/homeOpen.svg',
        navigateToPage: PAGES.Discover,
    },
    {
        linkName: 'Agents',
        linkIcon: '/assets/images/robot.svg',
        navigateToPage: PAGES.MyAgents,
    },
];
