var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
const DownloadModal = ({ open, handleClose, isMacOs, isInstalled, }) => {
    const modalContent = {
        title: 'Playlabs Launcher Download',
        description: isInstalled
            ? 'Download the correct launcher for your machine. If the install doesn`t work for windows, try unzipping it.'
            : 'For our AI Agent to work properly, you need to install our Launcher on your computer.',
    };
    const [downloadUrls, setDownloadUrls] = useState({
        'mac-arm': '',
        windows: '',
        'mac-intel': '',
    });
    const versionUrl = '/downloads/stable/version.txt';
    useEffect(() => {
        const fetchVersion = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const response = yield fetch(versionUrl);
                if (!response.ok) {
                    throw new Error(`Error fetching version: ${response.statusText}`);
                }
                const version = (yield response.text()).trim();
                setDownloadUrls({
                    'mac-arm': `/downloads/stable/Altera%20PlayLabs-${version}-arm64.dmg`,
                    windows: `/downloads/stable/Altera%20PlayLabs%20Setup%20${version}.exe`,
                    'mac-intel': `/downloads/stable/Altera%20PlayLabs-${version}.dmg`,
                });
            }
            catch (error) {
                console.error('Error fetching version:', error);
            }
        });
        fetchVersion();
    }, []);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 720,
        bgcolor: '#1E2022',
        boxShadow: 24,
        borderRadius: '35px',
        padding: '50px 10px',
    };
    return (_jsx(_Fragment, { children: _jsx(Modal, { open: open, onClose: handleClose, "aria-labelledby": 'modal-modal-title', "aria-describedby": 'modal-modal-description', sx: {
                borderRadius: '20px',
            }, children: isMacOs ? (_jsxs(Box, { sx: style, children: [_jsx(IconButton, { "aria-label": 'close', onClick: handleClose, size: 'small', sx: {
                            color: 'white',
                            position: 'absolute',
                            right: '20px',
                            top: '10px',
                            fontSize: '14px',
                        }, children: "\u2715" }), _jsxs("div", { className: styles['modal-content'], children: [_jsx(Typography, { id: 'modal-modal-title', variant: 'h4', component: 'h2', sx: {
                                    fontFamily: 'Roboto Mono',
                                    fontSize: '28px',
                                    textAlign: 'center',
                                    fontWeight: '700',
                                }, children: modalContent.title }), _jsx(Typography, { id: 'modal-modal-description', sx: {
                                    mt: 2,
                                    fontFamily: 'Roboto Mono',
                                    fontSize: '20px',
                                    alignSelf: 'center',
                                    marginBottom: '30px',
                                    padding: '0 20px',
                                }, children: modalContent.description }), _jsxs("div", { className: styles['install_macButtons'], children: [_jsxs(Button, { onClick: () => window.open(downloadUrls['mac-arm'], '_blank'), sx: {
                                            backgroundColor: '#ABC9B2',
                                            color: '#1D2022',
                                            fontSize: '16px',
                                            fontFamily: 'Roboto Mono',
                                            borderRadius: '12px',
                                            padding: '10px 0px',
                                            paddingRight: '12px',
                                            marginBottom: '10px',
                                            textTransform: 'none',
                                            '&:hover': {
                                                backgroundColor: '#728e76',
                                                color: '#1D2022',
                                            },
                                            width: '220px',
                                        }, children: [_jsx("img", { src: '/assets/images/apple.png', alt: 'apple-logo', width: 50, height: 28 }), _jsx(Typography, { sx: {
                                                    fontFamily: 'Roboto Mono',
                                                    fontSize: '26px',
                                                    fontWeight: 'bold',
                                                }, children: "MacOS ARM" })] }), _jsx(Button, { onClick: () => window.open(downloadUrls['mac-intel'], '_blank'), variant: 'text', sx: {
                                            color: '#ABC9B2',
                                            fontSize: '16px',
                                            fontFamily: 'Roboto Mono',
                                            textTransform: 'none',
                                            width: '220px',
                                        }, children: "Download MacOS Intel" })] })] })] })) : (_jsxs(Box, { sx: style, children: [_jsx(IconButton, { "aria-label": 'close', onClick: handleClose, size: 'small', sx: {
                            color: 'white',
                            position: 'absolute',
                            right: '20px',
                            top: '10px',
                            fontSize: '14px',
                        }, children: "\u2715" }), _jsxs("div", { className: styles['modal-content'], children: [_jsx(Typography, { id: 'modal-modal-title', variant: 'h4', component: 'h2', sx: {
                                    fontFamily: 'Roboto Mono',
                                    fontSize: '28px',
                                    textAlign: 'center',
                                }, children: modalContent.title }), _jsx(Typography, { id: 'modal-modal-description', sx: {
                                    mt: 2,
                                    fontFamily: 'Roboto Mono',
                                    fontSize: '20px',
                                    alignSelf: 'center',
                                    marginBottom: '30px',
                                    padding: '0 20px',
                                }, children: modalContent.description }), _jsxs(Button, { onClick: () => window.open(downloadUrls['windows'], '_blank'), sx: {
                                    backgroundColor: '#ABC9B2',
                                    color: '#1D2022',
                                    fontSize: '16px',
                                    fontFamily: 'Roboto Mono',
                                    borderRadius: '12px',
                                    padding: '10px 20px',
                                    marginBottom: '10px',
                                    textTransform: 'none',
                                    '&:hover': {
                                        backgroundColor: '#728e76',
                                        color: '#1D2022',
                                    },
                                    width: '220px',
                                }, children: [_jsx("img", { src: '/assets/images/windows.png', alt: 'windows-logo', width: 24, height: 24, className: styles['windows'] }), '   ', _jsx(Typography, { sx: {
                                            fontFamily: 'Roboto Mono',
                                            fontSize: '26px',
                                            fontWeight: 'bold',
                                        }, children: "Windows" })] })] })] })) }) }));
};
export default DownloadModal;
