var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
export var FEEDBACK_STATUS;
(function (FEEDBACK_STATUS) {
    FEEDBACK_STATUS["Success"] = "success";
    FEEDBACK_STATUS["Rejected"] = "rejected";
    FEEDBACK_STATUS["Pending"] = "pending";
})(FEEDBACK_STATUS || (FEEDBACK_STATUS = {}));
const EXPRESS_SERVER_URL = 'http://localhost:3003';
const axiosInstance = axios.create({
    baseURL: EXPRESS_SERVER_URL,
});
const initialState = {
    summonAgentStatus: 'none',
    messages: [],
};
// This only applies to localhost dev mode.
export const pollForMessages = createAsyncThunk('poll/for/messages', () => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield axiosInstance.get('/api/v1/messages');
    console.log('res', res);
    return res.data;
}));
export const workerSlice = createSlice({
    name: 'workerSlice',
    initialState,
    reducers: {
        appendWorkerMessage: (state, action) => {
            state.messages.push(action.payload);
        },
        setSummonAgentStatus: (state, action) => {
            state.summonAgentStatus = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(pollForMessages.fulfilled, (state, action) => {
            const message = action.payload;
            state.messages.push(message);
        });
    },
});
export const { appendWorkerMessage, setSummonAgentStatus } = workerSlice.actions;
export default workerSlice.reducer;
